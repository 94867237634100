import "./DashBoard.css";
import UserProjects from "components/stages/UserProjects";
import SubmitProject from "components/stages/SubmitProject";
import ActiveJobs from "components/stages/ActiveJobs";
import AllCompanies from "components/stages/AllCompanies";
import AllUsers from "components/stages/AllUsers";
import TicketDetail from "components/stages/details/TicketDetail";
import Driving from "components/stages/Driving";
import Auth from "utils/auth";
import BuyerJobs from "components/stages/BuyerJobs";
import JobDetail from "components/stages/details/JobDetail";
import CompanyTickets from "components/stages/CompanyTickets";
import CompanyJobs from "components/stages/CompanyJobs";
import Companytrucks from "components/stages/Companytrucks";
import CompanyUsers from "components/stages/CompanyUsers";
import CompanyDetail from "components/stages/details/CompanyDetail";
import BottomNav from "components/BottomNav";
import SignedInDrivers from "components/stages/SignedInDrivers";
import SystemLogs from "components/stages/SystemLogs";
import TruckDetail from "components/stages/details/TruckDetail";
import UserDetail from "components/stages/details/UserDetail";
import SubmitJob from "components/stages/submitJob";
import Quickbooks from "components/stages/Quickbooks";
import TruckTicket from "components/stages/details/TruckTicket";
import ProjectDetail from "components/stages/details/ProjectDetail";
import Board from "components/stages/board/";
import Schedule from "components/stages/Schedule";
import OpenActiveJobDetail from "components/stages/details/OpenActiveJobDetail";
import { useSearchParams } from 'react-router-dom';

const Stages = {
	Board:"Board",
	ProjectDetail:"ProjectDetail",
	UserProjects:"UserProjects",
	SubmitProject:"SubmitProject",
	OpenActiveJobDetail:"OpenActiveJobDetail",
	TruckTicket:"TruckTicket",
	Ticket:"Ticket",
	Schedule:"Schedule",
	Quickbooks:"Quickbooks",
	ActiveJobs: "ActiveJobs",
	SubmitJob:"SubmitJob",
	CompanyJobs:"CompanyJobs",
	AllUsers:"AllUsers",
	TruckDetail:"TruckDetail",
	TicketDetail:"TicketDetail",
	CompanyDetail:"CompanyDetail",
	Companytrucks:"Companytrucks",
	SystemLogs:"SystemLogs",
	BuyerJobs: "BuyerJobs",
	CompanyTickets: "CompanyTickets",
	AllCompanies:"AllCompanies",
	CompanyUsers: "CompanyUsers",
	UserDetail: "UserDetail",
	JobDetail: "JobDetail",
	SignedInDrivers: "SignedInDrivers",
	Driving: "Driving",
	Invoices: "Invoices",
};

const localStrings = {
  en:{
    ActiveJobs:"Active jobs",
		Schedule:"Schedule",
		Board:"Board",
		Ticket:"Ticket",
		Quickbooks:"Quickbooks",
		CompanyJobs:"Jobs",
    Companytrucks:"Trucks",
    AllUsers:'All Users',
    AllCompanies:"Companies",
		SubmitJob:"New Job",
    SystemLogs:"System Logs",
    CompanyTickets:"Org. Assignments",
		// Invoices:"Invoices",
    CompanyUsers:"Users",
		UserProjects:"Projects",
		SubmitProject:"Create Project",
    BuyerJobs:"Buyers jobs",
    SignedInDrivers:"Signed In Drivers",
    Driving: "Driving",
    MyTrucks:"My Trucks"
  },
  es:{
    ActiveJobs:"Trabajos activos"
  }
};


export default function Dashboard() {
	let displayStage;
	const [searchParams] = useSearchParams();
	const userData = Auth.getUserData();
	let stage = searchParams.get('stage');
	if(stage == null){
		if(userData.role === 'ceo'){
			stage = "ActiveJobs"
		}
		if(userData.role === 'driver'){
			stage = "Driving"
		}
	}
	
	switch (stage) {
		case Stages.ProjectDetail:
			displayStage = <ProjectDetail/>; 
			break;
		case Stages.UserProjects:
			displayStage = <UserProjects/>; 
			break;
		case Stages.SubmitProject:
			displayStage = <SubmitProject/>; 
			break;
		case Stages.Schedule:
			displayStage = <Schedule/>; 
			break;
		case Stages.OpenActiveJobDetail:
			displayStage = <OpenActiveJobDetail/>; 
		break;
		case Stages.Board:
			displayStage = <Board/>; 
			break;
		case Stages.Ticket:
		case Stages.TruckTicket:
			displayStage = <TruckTicket/>; 
			break;
		case Stages.Quickbooks:
			displayStage = <Quickbooks/>; 
			break;
		case Stages.SubmitJob:
			displayStage = <SubmitJob/>; 
			break;
		case Stages.CompanyJobs:
			displayStage = <CompanyJobs/>; 
			break;
		case Stages.TruckDetail:
			displayStage = <TruckDetail/>; 
			break;
		case Stages.TicketDetail:
			displayStage = <TicketDetail/>; 
			break;
		case Stages.Companytrucks:
			displayStage = <Companytrucks/>; 
			break;
		case Stages.CompanyDetail:
			displayStage = <CompanyDetail/>; 
			break;
		case Stages.AllUsers:
			displayStage = <AllUsers/>; 
			break;
		case Stages.AllCompanies:
			displayStage = <AllCompanies/>; 
			break;
		case Stages.CompanyTickets:
			displayStage = <CompanyTickets/>; 
			break;
		case Stages.CompanyUsers:
			displayStage = <CompanyUsers/>; 
			break;
		case Stages.Driving:
			displayStage = <Driving/>; 
			break;
		case Stages.UserDetail:
			displayStage = <UserDetail/>; 
			break;
		case Stages.SystemLogs:
			displayStage = <SystemLogs/>;
			break;
		case Stages.SignedInDrivers:
			displayStage = <SignedInDrivers/>;
			break;
		case Stages.BuyerJobs:
			displayStage = <BuyerJobs/>; 
			break;
		case Stages.JobDetail:
			displayStage = <JobDetail/>; 
			break;
		case Stages.ActiveJobs:
			displayStage = <ActiveJobs/>; 
			break;
		default:
			displayStage = <div> Click on a nav bar option </div>;
			break;
	}
	return (
		<div className="flex flex-col bg-[var(--gray)] h-screen w-full overflow-hidden">
			<BottomNav localStrings={localStrings}
				currentStage={stage}/>
			<div className="flex justify-center w-full h-screen overflow-auto ">
				{displayStage ?? <div> Error in staging </div>}
			</div>

		</div>
	);
}
