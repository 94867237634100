import Auth from "./auth";

async function goFetch(path,opts){
  if(!opts){
    opts = {
    };
  }
  if(!opts.headers){
    opts.headers = {};
  }
  const token = Auth.getToken()
  if(token){
    opts.headers['x-access-token'] = token;
  }
  opts.headers['user-language'] = localStorage.getItem("language")
  opts.headers['platform'] = 'web'
  opts.headers['Access-Control-Allow-Origin'] = '*'

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // console.log('/api'+path);
  } else {
    // production code
    // This needs to be put back in for server.
    // path = '/api'+path;
  }  
  
  // I am sorry for this, it is my current only fix.
  //if(window.location.hostname !== 'localhost'){
    //return await fetch('http://'+window.location.hostname+':3001'+path,opts ); 
  //}
  
  const { fetch: originalFetch } = window;
  window.fetch = async (...args) => {
    let [resource, config] = args;
    let response = await originalFetch(resource, config);
    if (!response.ok && response.status === 401) {
      // 401 error handling
      // we logout of the user.
      Auth.logout();
      return Promise.reject(response);
    }
    return response;
  };
  return await fetch(path,opts);
}

export default goFetch;