import decode from "jwt-decode";

class AuthService {
	userPerm(operation){
		let token = this.getToken();
		if (!token) return false;
		const decodedData = decode(token);
		if(Array.isArray(operation) ){
			if(decodedData.data.permissions?.length &&  operation.find(opt => decodedData.data.permissions.find(perm => opt === perm)) ){
				return true;
			}
		} else {
			if(decodedData.data.permissions?.length &&  decodedData.data.permissions.find(perm => perm === operation) ){
				return true;
			}
		}

		return false;
	}
	getUser() {
		let token = this.getToken();
		if (!token) return false;
		return decode(token);
	}
	getUserData() {
		let token = this.getToken();
		if (!token) return false;
		const decodedData = decode(token);
		return decodedData.data;
	}
	loggedIn() {
		try{
			const token = this.getToken();
			const decoded = decode(token);
			if(Date.now() > decoded.exp * 1000 ){
				localStorage.removeItem("id_token");
				return false;
			}
			return true;
		}catch(err){
			return false;
		}
	}

	getToken() {
		return localStorage.getItem("id_token");
	}

	login(idToken) {
		localStorage.setItem("id_token", idToken);
	}

	logout() {
		localStorage.removeItem("id_token");
		window.location.replace("/");
	}
}

const Auth = new AuthService();
export default Auth;
