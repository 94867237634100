import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {Route, BrowserRouter as Router, Routes} from "react-router-dom"
import About from 'pages/About';
import AcctCreate from './pages/AcctCreate'
import Contact from './pages/Contact.js'
import DashBoard from './pages/DashBoard.js'
import FAQ from 'pages/FAQ';
import Navbar from './components/Navbar.js'
import Onboarding from './pages/Onboarding.js';
import Landing from './pages/Landing.js';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ResetPassword from 'pages/ResetPassword';
import SelectLanguage from './pages/SelectLanguage';
import TOS from 'pages/TermsOfService';
import UserAgreement from 'pages/UserAgreement';
import UserHome from './pages/UserHome';
import UserSettings from 'pages/UserSettings';
import Fulfillment from 'pages/Fulfillment';
import {useState} from 'react';
import Auth from 'utils/auth';

function App() {
  const [language,setLanguage] = useState(localStorage.getItem("language"))
  const [logged, setLogged] = useState(Auth.loggedIn());
  const queryClient = new QueryClient()

  function updateLanguage(lang){
    localStorage.setItem("language",lang);
    setLanguage(lang);
  }

  if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){

  }

  return (
    <>
      <Router>
        <QueryClientProvider client={queryClient}>
            {!language ? <SelectLanguage setLanguage={updateLanguage}/> :
            <>
               {logged ? <></> : <Navbar logged={logged}/>} 
              <Routes>
                <Route path="/" element={<Landing/>} />
                <Route path="/home" element={<Landing/>} />
                <Route path="/login" element={<Onboarding setLogged={setLogged}/>} />
                <Route path="/contact" element={<Contact/>} />
                <Route path="/resetPassword/:userId" element={<ResetPassword/>}/>
                <Route path="/userhome" element={<UserHome/>}/>
                <Route path="/dashboard" element={<DashBoard/>}/>
                <Route path="/createAcct/:inviteId" element={<AcctCreate/>}/>
                <Route path="/settings" element= {<UserSettings/>}/>
                <Route path="/about" element={<About/>} />
                <Route path="/faq" element={<FAQ/>} />
                <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
                <Route path="/fulfillment" element={<Fulfillment/>} />
                <Route path="/tos" element={<TOS/>} />
                <Route path="/useragreement" element={<UserAgreement/>}/>
              </Routes>
            </>
            }
          <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        </QueryClientProvider>
      </Router>
    </>
  );
}

export default App;