import "./Login.css";
import Auth from "../utils/auth";
import goFetch from "../utils/goFetch";
import { useState,useRef,useEffect } from "react";
import { loginStrings } from "./Login.local";
import { Localization } from "utils";
import { useNavigate } from 'react-router-dom'
const Login = (props) => {
    const [formState, setFormState] = useState({ username: "", password: "" });
    const [error,setError] = useState();
    const strings = new Localization(loginStrings).strings;
    const forgotEmail = useRef()
    const [forgot,setForgot] = useState(false);
    const navigate = useNavigate();
    
    const handleChange = (event) => {
      const { name, value } = event.target;
      setError(null);
      setFormState({
        ...formState,
        [name]: value,
      });
    };

    const handleFormSubmit = async (event) => {
      event.preventDefault();
      try {
        const rspnse = await goFetch('/users/login/', {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formState)
        });
        const rpn = await rspnse.json();
        if(rpn.error){
          throw rpn.error
        }
        if(rpn.token){
         Auth.login(rpn.token);  
          props.setLogged(true);
          navigate("/userhome")
        }

        if(rpn.user && !rpn.user.language){
          await goFetch('/users/setUserLanguage/'); //goFetch gets localStorage.language and adds it to the header
        } else if( rpn.user?.language){
          localStorage.setItem("language",rpn.user.language);
        }

      } catch (e) {
        setError(e);
      }
  
      // clear form values
      setFormState({
        username: "",
        password: "",
      });
    };

    async function sendForgot(evt){
      const email = forgotEmail.current.value.toLowerCase();
      const rspnse = await goFetch('/users/sendPswdEmail/', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({email})
      });

      const rpn = await rspnse.json();
      if(rpn.success){
        alert(rpn.success)
      }
      return rpn;      
    }

    useEffect(() => {
      if(Auth.loggedIn()){
        navigate("/userhome")
      }
    }, [navigate])
    
  
    return (
      <div className="loginCard">
        <h1 className="loginCardTitle">{strings.userLogin}</h1>
          <form onSubmit={handleFormSubmit} className="loginFormContainer">
              <input
                className="loginFormInput"
                placeholder={strings.usernamePlacehold}
                name="username"
                type="username"
                value={formState.username}
                onChange={handleChange}
                style={error ? {backgroundColor:"red"} : null}
              />
              <input
                className="loginFormInput"
                placeholder="******"
                name="password"
                type="password"
                value={formState.password}
                onChange={handleChange}
                style={error ? {backgroundColor:"red"} : null}
              />
              <button
                onClick={handleFormSubmit}
                className="oBtn" id="loginSubmitBtn"
                style={{ cursor: "pointer" }}
                type="submit"
              >
                {strings.login}
              </button>
              {/* <button className="createNewAccountBtn"
               onClick={toggleExistingUser}
               style={{ cursor: "pointer" }}
               >
                Create Account
              </button> */}
              <small className="forgotPassword cursor-pointer" onClick={()=>setForgot(!forgot)}>{strings.forgotPassword}</small>
          </form>
          {error && (
          <div className="text-red-400 ">{error.message}</div>
        )}

          {
            forgot ?
            <div> 
              <h1> Please enter email below </h1>
              <input
                className="loginFormInput h-8"
                name= "email"
                type="email"
                placeholder="johndoe@email.com"
                ref = {forgotEmail}
                style={error ? {backgroundColor:"red"} : null}
              />
              <button onClick={sendForgot} className = "oBtn">Confirm</button>
            </div>
            :
            <></>
          }
  
      </div>
    );
  };
  
  export default Login;
  